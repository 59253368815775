import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import ContactModal from '../components/contactmodal'

const FourOhFourPage = () => {
  return (
    <MDBContainer>
      <section id="404" className="my-5">
        <h2 className="h1-responsive my-5">404 Page not found</h2>
        <p className="lead dark- w-responsive mx-auto mb-5">
          Opps, please let us know
        </p>
        <MDBRow>
          <MDBCol md="4" className="text-name">
            <img
              className="img-fluid"
              src="https://mdbootstrap.com/img/Mockups/Transparent/Small/iphone-portfolio1.png"
              alt=""
            />
          </MDBCol>
        </MDBRow>

        <ContactModal />
      </section>
    </MDBContainer>
  )
}

export default FourOhFourPage
